import { createTheme } from "@mui/material/styles";
import { heritageV2Palette } from "./heritageV2Palette";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { DesignTokens } from "@heritageholdings/lib-commons-frontend";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import type {} from "@mui/x-data-grid-premium/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

const baseFontSize = 16;

function fontSizeToRem(fontSize: number) {
  return `${fontSize / baseFontSize}rem`;
}

function formatLineHeight(lineHeight: number) {
  return `${lineHeight}px`;
}

/**
 * Typography following the definitive Design System.
 */
const themeV2Typography: TypographyOptions = {
  htmlFontSize: baseFontSize,
  fontSize: baseFontSize,

  h1: {
    fontFamily: "'Playfair Display', sans-serif;",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize500),
    fontWeight: DesignTokens.fontWeights.fontWeight500,
  },
  h2: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize300),
    fontWeight: DesignTokens.fontWeights.fontWeight600,
  },
  h3: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize200),
    fontWeight: DesignTokens.fontWeights.fontWeight600,
  },
  h4: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize100),
    fontWeight: DesignTokens.fontWeights.fontWeight600,
    color: heritageV2Palette.neutral700,
  },
  h6: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize80),
    fontWeight: DesignTokens.fontWeights.fontWeight500,
    textTransform: "uppercase",
  },
  menuItem: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize100),
    fontWeight: DesignTokens.fontWeights.fontWeight500,
  },
  formLabel: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize90),
    fontWeight: DesignTokens.fontWeights.fontWeight400,
  },
  // This is `Paragraph` in the Design System.
  body1: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize100),
    fontWeight: DesignTokens.fontWeights.fontWeight300,
  },
  // This is `Paragraph small` in the Design System.
  body2: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize80),
    fontWeight: DesignTokens.fontWeights.fontWeight400,
    lineHeight: formatLineHeight(DesignTokens.lineHeights.lineHeight300),
  },
  largeMessage: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize400),
    fontWeight: DesignTokens.fontWeights.fontWeight400,
  },
  tdLabel: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize90),
    fontWeight: DesignTokens.fontWeights.fontWeight400,
  },
  thLabel: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize90),
    fontWeight: DesignTokens.fontWeights.fontWeight600,
  },
  figuresNumber: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize400),
    fontWeight: DesignTokens.fontWeights.fontWeight600,
  },
  figuresSymbol: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize300),
    fontWeight: DesignTokens.fontWeights.fontWeight300,
  },
  button: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize90),
    fontWeight: DesignTokens.fontWeights.fontWeight600,
  },
  miniTextButton: {
    fontFamily: "Inter, sans-serif",
    fontSize: fontSizeToRem(DesignTokens.fontSizes.fontSize90),
    fontWeight: DesignTokens.fontWeights.fontWeight400,
  },
};

/**
 *  Spacing following the definitive Design System.
 */
const themeV2Spacing = [
  // FIXME: This might need to be sorted, since the `Object.values`
  // function do not guarantee the order of the values.
  ...Object.values(DesignTokens.spacings).slice(2),

  // These values are currently used by the old views
  // and ideally will be removed when we achieve the full
  // design migration.
  56,
  64,
  72,
  80,
  88,
];

export const getTheme = () => {
  return createTheme({
    typography: themeV2Typography,
    palette: {
      heritageV2: heritageV2Palette,
    },
    spacing: themeV2Spacing,
    shape: {
      borderRadius: DesignTokens.radii.radius100,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 0,
        md: 720,
        lg: 1120,
        xl: 1440,
        xxl: 1920,
      },
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },

        styleOverrides: {
          elevation: {
            boxShadow:
              "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2) !important",
          },

          elevation0: {
            "&:not(.MuiAutocomplete-paper)": {
              boxShadow: "none !important",
            },
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            color: heritageV2Palette.text900,

            "& > strong": {
              fontWeight: DesignTokens.fontWeights.fontWeight600,
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              borderColor: heritageV2Palette.accent,
            },
          },
          notchedOutline: {
            borderColor: heritageV2Palette.neutral500,
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            width: "100%",

            "& .MuiInputLabel-outlined:not(.Mui-error)": {
              color: heritageV2Palette.neutral700,
            },

            "& .MuiInputLabel-outlined.Mui-focused": {
              color: heritageV2Palette.accentLight,
            },

            "& .MuiInputLabel-outlined.Mui-error": {
              color: heritageV2Palette.statusNegative,
            },
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: themeV2Typography.body2?.fontSize,
            fontWeight: themeV2Typography.body2?.fontWeight,
            color: heritageV2Palette.text900,
            marginTop: DesignTokens.spacings.spacing50,

            "&.Mui-error": {
              color: heritageV2Palette.statusNegative,
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            color: heritageV2Palette.text900,
            outline: "none !important",
            fontWeight: `${
              themeV2Typography.formLabel?.fontWeight ?? "400"
            } !important`,

            // Hover border color for a `Select` component
            // created through a `TextField`.
            ":hover .MuiOutlinedInput-notchedOutline": {
              borderColor: `${heritageV2Palette.neutral700} !important`,
            },

            // Focus border color for a `Select` component
            // created through a `TextField`.
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: `${heritageV2Palette.accentLight} !important`,
              borderWidth: "1px !important",
            },

            "&.Mui-disabled": {
              backgroundColor: heritageV2Palette.neutral300,
              borderColor: heritageV2Palette.neutral500,
            },

            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: `${heritageV2Palette.statusNegative} !important`,
            },
          },

          input: {
            paddingLeft: `${themeV2Spacing[1]}px !important`,
            paddingRight: `${themeV2Spacing[3]}px !important`,
          },
        },
      },

      MuiPopover: {
        styleOverrides: {
          paper: {
            "& > .MuiList-root": {
              // This is the `Menu` component.
              // At the moment this is the `Select` dropdown element.
              "& > .MuiMenuItem-root": {
                fontWeight: themeV2Typography.formLabel?.fontWeight,
                fontSize: themeV2Typography.formLabel?.fontSize,

                ":hover": {
                  backgroundColor: `${heritageV2Palette.accentBg100} !important`,
                },

                "&.Mui-selected": {
                  backgroundColor: `${heritageV2Palette.accentBg200} !important`,
                },
              },

              "& .MuiListSubheader-root": {
                color: heritageV2Palette.neutral500,
              },
            },
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiToolbar: {
        styleOverrides: {
          root: {
            height: DesignTokens.sizes.size800,
          },
        },
      },

      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: heritageV2Palette.text900,
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          endAdornment: {
            "& .MuiButtonBase-root": {
              color: heritageV2Palette.neutral700,
            },
          },

          listbox: {
            "& .MuiAutocomplete-option": {
              "&:hover": {
                backgroundColor: heritageV2Palette.accentBg100 + " !important",
              },

              '&[aria-selected="true"]': {
                backgroundColor: heritageV2Palette.accentBg200 + " !important",
              },
            },
          },
        },
      },

      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: "none",
            background: heritageV2Palette.invariantWhite,

            "& .MuiDataGrid-container--bottom": {
              "& [role=row]:first-child": {
                borderTop: `2px solid ${heritageV2Palette.neutral300}`,
              },

              "& [role=row]:last-child span": {
                fontWeight: themeV2Typography.thLabel?.fontWeight,
              },
            },
          },

          footerContainer: {
            border: "none !important",
          },

          row: {
            "&:hover": {
              backgroundColor: heritageV2Palette.accentBg100,
            },

            "& .MuiDataGrid-cell": {
              borderColor: heritageV2Palette.backgroundPage,
              display: "flex",
              alignItems: "center",
            },

            "&:last-child .MuiDataGrid-cell": {
              borderBottom: "none",
            },

            "& .MuiDataGrid-cell span": {
              // This is used to normal cells. Since these are wrapped into
              // a .truncate class, and its white-space is `pre`, the text
              // inside will go to a new line with a `\n`. Adding line-height
              // will make the lines to be separated by a space.
              lineHeight: 1.8,
            },
          },

          columnHeaders: {
            borderColor: `${heritageV2Palette.neutral500} !important`,
          },

          columnHeader: {
            "&:focus-within": {
              outline: "none",
            },
          },

          columnHeaderTitle: {
            fontSize: themeV2Typography.thLabel?.fontSize,
            fontWeight: themeV2Typography.thLabel?.fontWeight,
          },

          columnHeaderTitleContainer: {
            "& .MuiButtonBase-root": {
              color: heritageV2Palette.neutral700,

              "&:hover": {
                backgroundColor: "transparent",
                color: heritageV2Palette.neutral700,
                borderColor: "transparent",
              },
            },
          },

          aggregationColumnHeaderLabel: {
            display: "none",
          },

          cell: {
            fontSize: themeV2Typography.tdLabel?.fontSize,
            fontWeight: themeV2Typography.tdLabel?.fontWeight,
            paddingTop: themeV2Spacing[1],
            paddingBottom: themeV2Spacing[1],

            "&:focus-within": {
              outline: "none",
            },

            "& .MuiButtonBase-root": {
              padding: "0px",
            },
          },

          overlayWrapper: {
            height: 150,
          },

          pinnedColumns: {
            boxShadow: "none",
            borderLeft: `1px solid ${heritageV2Palette.backgroundPage} !important`,
          },

          columnSeparator: {
            display: "none !important",
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            color: heritageV2Palette.accent,
            borderRadius: DesignTokens.radii.radius100,
            transition: "none",
            aspectRatio: 1,

            "& svg path[fill]": {
              fill: heritageV2Palette.accent,
            },

            "&:disabled": {
              color: heritageV2Palette.neutral500,
            },

            "&:hover": {
              color: heritageV2Palette.accentLight,

              "& svg path[fill]": {
                fill: heritageV2Palette.accentLight,
              },
            },

            // FIXME: In a newer version of the design system we
            // should have a `statusNegativeLight` color.
            "&.danger": {
              color: heritageV2Palette.statusNegative,
              opacity: 0.8,

              "&:hover": {
                color: heritageV2Palette.statusNegative,
                opacity: 1,
              },
            },
          },
        },

        defaultProps: {
          disableRipple: true,
        },
      },

      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },

        variants: [
          {
            props: { variant: "negative" },
            style: {
              border: "1px solid " + heritageV2Palette.statusNegative,
              backgroundColor: heritageV2Palette.statusNegative,
              color: heritageV2Palette.invariantWhite,
              "&:hover": {
                borderColor: heritageV2Palette.statusNegativeLight,
                backgroundColor: heritageV2Palette.statusNegativeLight,
              },
            },
          },
          {
            props: { variant: "outlined-negative" },
            style: {
              border: "1px solid " + heritageV2Palette.statusNegative,
              color: heritageV2Palette.statusNegative,

              "&:hover": {
                borderColor: heritageV2Palette.statusNegativeLight,
                backgroundColor: heritageV2Palette.statusNegativeLight,
              },

              "&.variant-secondary-dark": {
                borderColor: heritageV2Palette.statusNegative,
                color: heritageV2Palette.statusNegative,

                "&:hover": {
                  borderColor: heritageV2Palette.statusNegativeLight,
                },
              },
            },
          },
          {
            props: { variant: "text-negative" },
            style: {
              color: heritageV2Palette.statusNegative,
              fontSize: themeV2Typography.miniTextButton?.fontSize,
              fontWeight: themeV2Typography.miniTextButton?.fontWeight,

              padding: "0px",
              width: "auto",
              display: "inline-block",

              "&:hover": {
                color: heritageV2Palette.statusNegativeLight,
                backgroundColor: "transparent",
              },
            },
          },
        ],

        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
            width: "100%",
            paddingTop: themeV2Spacing[0],
            paddingBottom: themeV2Spacing[0],
            paddingLeft: themeV2Spacing[2],
            paddingRight: themeV2Spacing[2],
            transition: "none",
            fontSize: themeV2Typography.button?.fontSize,
            fontWeight: themeV2Typography.button?.fontWeight,
            textTransform: "none",

            display: "flex !important",
            flexDirection: "row",
            alignItems: "center",

            "&:hover": {
              borderColor: heritageV2Palette.accentLight,
              backgroundColor: heritageV2Palette.accentLight,
              color: heritageV2Palette.invariantWhite,
            },

            "&:disabled": {
              backgroundColor: heritageV2Palette.neutral500,
              borderColor: heritageV2Palette.neutral500,
              color: heritageV2Palette.neutral300,
            },
          },

          startIcon: {},

          contained: {
            border: "1px solid " + heritageV2Palette.accent,
            backgroundColor: heritageV2Palette.accent,
          },

          outlined: {
            borderColor: heritageV2Palette.accent,
            color: heritageV2Palette.accent,

            "&.variant-secondary-dark": {
              borderColor: heritageV2Palette.invariantWhite,
              color: heritageV2Palette.invariantWhite,

              "&:hover": {
                borderColor: heritageV2Palette.accentLight,
              },
            },
          },

          text: {
            color: heritageV2Palette.accent,
            fontSize: themeV2Typography.miniTextButton?.fontSize,
            fontWeight: themeV2Typography.miniTextButton?.fontWeight,

            padding: "0px",
            width: "auto",
            display: "inline-block",

            "&:hover": {
              color: heritageV2Palette.accentLight,
              backgroundColor: "transparent",
            },
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: themeV2Spacing[3],
          },
        },
      },

      MuiBadge: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: heritageV2Palette.accent,
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            color: heritageV2Palette.accent + " !important",
            padding: "0px",

            "& + .MuiTypography-root": {
              marginLeft: themeV2Spacing[0],
            },

            "&.Mui-checked": {
              color: heritageV2Palette.accent + " !important",
            },
          },
        },

        defaultProps: {
          disableRipple: true,
        },
      },

      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "0px",
            marginTop: themeV2Spacing[2],
          },
        },
      },

      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "0px",
            marginTop: themeV2Spacing[2],
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            backgroundColor: heritageV2Palette.invariantWhite,
            color: heritageV2Palette.neutral700 + " !important",
            padding: "0px",

            "&.Mui-checked": {
              color: heritageV2Palette.accent + " !important",
            },
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: "0px",

            "& .MuiTypography-root": {
              fontSize: themeV2Typography.formLabel?.fontSize,
              fontWeight: themeV2Typography.formLabel?.fontWeight,
            },

            "&:hover .MuiCheckbox-root": {
              "&:not(.Mui-checked) > div": {
                borderColor: heritageV2Palette.accentLight + " !important",
              },

              "& + .MuiTypography-root": {
                color: heritageV2Palette.accentLight + " !important",
              },
            },

            "& .MuiCheckbox-root": {
              "& + .MuiTypography-root": {
                marginLeft: themeV2Spacing[0],
              },
            },
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          dayWithMargin: {
            "&.Mui-selected": {
              backgroundColor: heritageV2Palette.accent + " !important",
            },

            "&:hover": {
              backgroundColor: heritageV2Palette.accentBg100 + " !important",
            },
          },

          today: {
            borderColor: heritageV2Palette.accent + " !important",
          },
        },
      },

      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            "&.Mui-selected": {
              backgroundColor: heritageV2Palette.accent + " !important",
            },

            "&:hover": {
              backgroundColor: heritageV2Palette.accentBg100 + " !important",
            },
          },
        },
      },

      MuiDayCalendar: {
        styleOverrides: {
          header: {
            "& .MuiTypography-root": {
              color: heritageV2Palette.neutral500 + " !important",
            },
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            padding: themeV2Spacing[1],
            color: heritageV2Palette.invariantWhite,
            alignItems: "center",
            ...themeV2Typography.body2,

            "& .MuiLink-root": {
              color: heritageV2Palette.invariantWhite,
              textDecorationColor: heritageV2Palette.invariantWhite,
            },

            "& .MuiAlert-icon": {
              padding: "0px",
              color: heritageV2Palette.invariantWhite,
            },

            "& .MuiAlertTitle-root": {
              color: heritageV2Palette.invariantWhite,
              ...themeV2Typography.h3,
            },

            "& .MuiAlert-message": {
              padding: "0px",
            },

            "& a": {
              color: heritageV2Palette.invariantWhite,
              textDecorationColor: heritageV2Palette.invariantWhite,
            },
          },

          standardInfo: {
            backgroundColor: heritageV2Palette.chart400,
          },

          standardWarning: {
            backgroundColor: heritageV2Palette.chart900,
            color: heritageV2Palette.text900,

            "& .MuiLink-root": {
              color: heritageV2Palette.text900,
              textDecorationColor: heritageV2Palette.text900,
            },

            "& .MuiAlert-icon": {
              color: heritageV2Palette.text900,
            },

            "& .MuiAlertTitle-root": {
              color: heritageV2Palette.text900,
            },

            "& a": {
              color: heritageV2Palette.text900,
              textDecorationColor: heritageV2Palette.text900,
            },
          },

          standardError: {
            backgroundColor: heritageV2Palette.statusNegative,
          },

          standardSuccess: {
            backgroundColor: heritageV2Palette.statusPositive,
          },
        },
      },

      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: heritageV2Palette.accent,
          },
        },
      },
    },
  });
}; /**
 * Custom types.
 */

declare module "@mui/material/styles" {
  interface Palette {
    lowlight: Palette["primary"];
    lowEmphasis: Palette["primary"];
    table: {
      header: {
        text: string;
      };
      currentYear: {
        background: string;
        text: string;
      };

      groupingCell: {
        background: string;
        backgroundHover: string;
        text: string;
      };
      positiveValue: {
        text: string;
      };

      cell: { text: string };
    };
    heritageV2: typeof heritageV2Palette;
  }
  interface PaletteOptions {
    heritageV2: typeof heritageV2Palette;
  }

  interface TypographyVariants {
    menuItem: React.CSSProperties;
    formLabel: React.CSSProperties;
    largeMessage: React.CSSProperties;
    tdLabel: React.CSSProperties;
    thLabel: React.CSSProperties;
    figuresNumber: React.CSSProperties;
    figuresSymbol: React.CSSProperties;
    button: React.CSSProperties;
    miniTextButton?: React.CSSProperties;
  }

  // allow configuration using `createTheme`.
  interface TypographyVariantsOptions {
    menuItem?: React.CSSProperties;
    formLabel?: React.CSSProperties;
    largeMessage?: React.CSSProperties;
    tdLabel?: React.CSSProperties;
    thLabel?: React.CSSProperties;
    figuresNumber?: React.CSSProperties;
    figuresSymbol?: React.CSSProperties;
    button?: React.CSSProperties;
    miniTextButton?: React.CSSProperties;
  }

  // Breakpoints.
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    menuItem: true;
    formLabel: true;
    largeMessage: true;
    tdLabel: true;
    thLabel: true;
    figuresNumber: true;
    figuresSymbol: true;
    button: true;
    miniTextButton: true;
  }
}
